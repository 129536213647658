
import { Options, Vue } from 'vue-class-component';
import { LoyaltyRedemptionRuleViewPublic, LoyaltyRedemptionRule, LoyaltyCustomerViewPublic, LoyaltyAPIPublicAuth } from '@okendo/reviews-common';

import LoyaltyCard from './LoyaltyCard.vue';
import ProductToReview from '@/shared-components/ProductToReview.vue';
import FontAwesomeIcon from '@/shared-components/FontAwesomeIcon.vue';
import Icon from '@/shared-components/Icon.vue';
import store from '@/store';
import { getRewardCurrencyWithValue } from '@/utils/rewardUtils';
import { postRedemption } from '@/utils/loyaltyAuthenticatedApi';
import { StoreMethod } from '@/store/storeTypings';

@Options({
    components: {
        LoyaltyCard,
        ProductToReview,
        FontAwesomeIcon,
        Icon
    },
    props: {
        rule: Object
    },
    store
})
export default class LoyaltyRedeemCard extends Vue {
    isRedeeming = false;
    rule!: LoyaltyRedemptionRuleViewPublic;

    get isPreviewMode(): boolean {
        return store.state.subscriber.previewMode;
    }

    get storeUrl(): string {
        return store.state.subscriber.storeUrl;
    }

    get member(): LoyaltyCustomerViewPublic | null {
        return store.state.loyalty.member;
    }

    get memberBalance(): number {
        return this.member?.points.balance ?? 0;
    }

    get isRedeemable(): boolean {
        return this.member?.status === 'enrolled' && this.redemptionProgress ? this.redemptionProgress >= 1 : false;
    }

    get redemptionProgress(): number {
        return this.member?.status === 'enrolled' && this.rule
            ? this.member.points.balance / this.minimumPointsToRedeem
            : 0;
    }

    get minimumPointsToRedeem(): number {
        const ruleBasePoints = this.rule?.reward.points ?? 0;
        return this.rule?.reward && 'minimumPoints' in this.rule.reward
            ? this.rule.reward.minimumPoints ?? ruleBasePoints
            : ruleBasePoints;
    }

    get currencySymbol(): string {
        return store.state.subscriber.currencySymbol;
    }

    getSubtitle(): string | undefined {
        const points = getRewardCurrencyWithValue(this.rule.reward.points);
        return this.rule.type === 'variable-coupon'
            ? this.$t('Receive {currencySymbol}{value} for every {points}', {
                currencySymbol: this.currencySymbol,
                value: (this.rule.reward as LoyaltyRedemptionRule.VariableCoupon.Reward).value,
                points
            })
            : points;
    }

    async onRedeem(): Promise<void> {
        if (!this.rule || this.isPreviewMode) {
            return;
        }

        this.isRedeeming = true;

        const onCouponRedemptionError = (): void => {
            store.dispatch<StoreMethod>({
                type: 'alert/SHOW',
                alertData: {
                    content: this.$t('Error occurred redeeming reward, please try again'),
                    status: 'fail'
                }
            });
        };

        try {
            const { redemptionRuleId } = this.rule;
            const body: LoyaltyAPIPublicAuth.Redemptions.Post.Request = { redemptionRuleId };
            const { coupon, customerPoints } = await postRedemption(body);

            store.commit<StoreMethod>({
                type: 'loyalty/SET_POINTS',
                points: customerPoints
            });

            if (coupon.success) {
                try {
                    await store.dispatch<StoreMethod>({
                        type: 'loyalty/GET_COUPONS'
                    });

                    const couponElement = document.getElementById('loyalty-coupons');
                    couponElement?.scrollIntoView({ behavior: 'smooth' });

                    store.dispatch<StoreMethod>({
                        type: 'alert/SHOW',
                        alertData: {
                            content: this.$t('Reward redeemed!'),
                            status: 'success'
                        }
                    });
                }
                catch {}
            }
            else {
                onCouponRedemptionError();
            }
        }
        catch {
            onCouponRedemptionError();
        }
        finally {
            this.isRedeeming = false;
        }
    }
}
