import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, withModifiers as _withModifiers, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-df520a2e"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["disabled"]
const _hoisted_2 = {
  key: 0,
  class: "c-loader c-loader--small c-loader--buttonFontColor"
}
const _hoisted_3 = { key: 1 }
const _hoisted_4 = {
  key: 1,
  class: "c-loyaltyRedeem-progress"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_loyalty_card = _resolveComponent("loyalty-card")!

  return (_openBlock(), _createBlock(_component_loyalty_card, {
    key: _ctx.rule.redemptionRuleId,
    title: _ctx.rule.title,
    subtitle: _ctx.getSubtitle(),
    icon: _ctx.rule.icon,
    layout: "inline"
  }, {
    actions: _withCtx(() => [
      (_ctx.isRedeemable)
        ? (_openBlock(), _createElementBlock("button", {
            key: 0,
            disabled: _ctx.isRedeeming,
            onClick: _cache[0] || (_cache[0] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.onRedeem && _ctx.onRedeem(...args)), ["prevent"])),
            class: "c-button c-loyaltyModule-button"
          }, [
            (_ctx.isRedeeming)
              ? (_openBlock(), _createElementBlock("div", _hoisted_2))
              : (_openBlock(), _createElementBlock("span", _hoisted_3, _toDisplayString(_ctx.$t('Redeem')), 1))
          ], 8, _hoisted_1))
        : (_openBlock(), _createElementBlock("div", _hoisted_4, [
            _createElementVNode("div", {
              style: _normalizeStyle({ width: !_ctx.redemptionProgress ? '0%' : `calc(${_ctx.redemptionProgress * 100}% + 2px)` }),
              class: "c-loyaltyRedeem-progress-track"
            }, null, 4)
          ]))
    ]),
    _: 1
  }, 8, ["title", "subtitle", "icon"]))
}